<template>
  <div
    class="main-slider__btn btn primary --shadow banner-btn"
    @click="$iflatWidget.show()"
  >
    <img src="~/assets/img/svg/cms/main-slider/key.svg">
    {{ text || 'Выдача ключей' }}
  </div>
</template>

<script setup lang="ts">
const { $iflatWidget } = useNuxtApp();
const props = defineProps<{
	text?: string;
}>();

onMounted(() => {
  $iflatWidget.init({ id: '60e80e9c50172', isEmbed: false });
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.main-slider__btn {
	align-items: center;
	gap: 5px;

	& > img {
		width: 20px;
		height: 20px;
		margin-bottom: 3px;
	}
}
</style>
